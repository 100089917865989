/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import facebookCircularPlain from "./svg/facebook-circular-plain.svg";
import instagramCircularPlain from "./svg/instagram-circular-plain.svg";
import linkedinCircularPlain from "./svg/linkedin-circular-plain.svg";
import twitterCircularPlain from "./svg/twitter-circular-plain.svg";
import whatsappCircularPlain from "./svg/whatsapp-circular-plain.svg";
import youtubeCircularPlain from "./svg/youtube-circular-plain.svg";
import pinterestCircularPlain from "./svg/pinterest-circular-plain.svg";
import telegramCircularPlain from "./svg/telegram-circular-plain.svg";
import googleCircularPlain from './svg/google-circular-plain.svg';
import jioCircularPlain from './svg/jio-circular-plain.svg';
import redditCircularPlain from './svg/reddit-circular-plain.svg';
import tiktokCirclarPlain from './svg/tiktok-circular-plain.svg';

export const CircularPlainSocial = {
  facebook: facebookCircularPlain,
  twitter: twitterCircularPlain,
  instagram: instagramCircularPlain,
  linkedin: linkedinCircularPlain,
  youtube: youtubeCircularPlain,
  whatsapp: whatsappCircularPlain,
  pinterest: pinterestCircularPlain,
  telegram: telegramCircularPlain,
  jio: jioCircularPlain,
  google: googleCircularPlain,
  reddit: redditCircularPlain,
  tiktok: tiktokCirclarPlain
};
