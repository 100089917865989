/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useState, useEffect } from 'react';
import { CustomLink } from '../custom-link';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccessType } from '@quintype/components';
import LogedinUser from '../../../assets/icons/logedin-user-icon.svg';
import { SvgHandler } from '../svg-handler/svg-handler';
import { textWithFallback } from '../pages/subscription/helpers';
import './user-profile.m.css';
import { getPath } from '../../utils';

const UserDataBase = props => {
  const [showUserData, setShowUserData] = useState(false);
  const [loadingSubscriptionPlans, setLoadingSubscriptionPlans] = useState(false);

  useEffect(() => {
    if (!props.memberSubscriptions?.length && !loadingSubscriptionPlans && props.userLoaded) {
      getUserSubscriptions();
    }
    return;
  }, [props.memberSubscriptions, loadingSubscriptionPlans, props.userLoaded]);

  const accesstypeEnabled = get(
    props,
    ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration', 'enableAccesstype'],
    false
  );
  const enablePayPerAssetEnabled = get(
    props,
    ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration', 'enablePayPerAsset'],
    false
  );
  const { enableLocalization = false, localizedElements = {} } = get(
    props,
    ['config', 'pagebuilder-config', 'general', 'localization'],
    {}
  );
  const manageSubscriptionsText = get(
    props,
    ['config', 'pagebuilder-config', 'manage-subscriptions', 'pageSetting', 'manageSubscriptionsDropdownText'],
    'Manage Subscriptions'
  );
  const profilePageText = get(
    props,
    ['config', 'pagebuilder-config', 'profilePage', 'pageSetting', 'dropdownProfileText'],
    'My Profile'
  );
  const purchasedPageText = get(
    props,
    ['config', 'pagebuilder-config', 'purchasedStoryPage', 'pageSetting', 'dropdownPurchasedText'],
    'Purchased Stories'
  );
  const sketchesHost = get(props, ['config', 'sketches-host'], '');
  const whiteListedUrlParams = get(
    props,
    ['config', 'pagebuilder-config', 'general', 'others', 'whiteListedUrlParams'],
    []
  );
  const { signInSignUp = {} } = enableLocalization ? localizedElements : {};
  const { signOut: localizedSignOut } = signInSignUp;
  const manageSubsLink = getPath({
    sketchesHost,
    relativePath: '/manage-subscriptions',
    defaultParams:
      window?.location?.pathname === '/manage-subscriptions' ? '' : `redirectUrl=${window?.location?.pathname}`,
    injectCustomParams: true,
    whiteListedUrlParams,
    swapDefaultCustomParams: true
  });

  const getUserSubscriptions = async () => {
    setLoadingSubscriptionPlans(true);
    const { subscriptions } = await props.getSubscriptionForUser();
    props.updateMemberSubscriptions(subscriptions);
    props.memberSubscriptionsLoaded();
  };

  const toggleUserData = () => {
    setShowUserData(!showUserData);
  };

  const logoutHandler = async event => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    try {
      const url = getPath({
        sketchesHost: get(props, ['config', 'sketches-host'], ''),
        relativePath: '/api/auth/v1/logout',
        injectCustomParams: true,
        whiteListedUrlParams: get(
          props,
          ['config', 'pagebuilder-config', 'general', 'others', 'whiteListedUrlParams'],
          []
        )
      });
      await fetch(url).then(() => {
        global.talktype && global.talktype.accountUserLogout();
        props.updateMember(null);
        window.location.reload();
      });
    } catch (err) {
      return console.log('Logout error: ', err);
    }
  };

  const fallbackImage = () => {
    const image = get(props, ['member', 'avatar-url']);
    if (image) {
      return <img styleName='avatar' src={image} loading='lazy' alt='author-avatar' />;
    }
    return (
      <span>
        <SvgHandler xlinkHref={LogedinUser} clazzName='author-icon' width='24' height='24' viewBox='0 0 24 24' />
      </span>
    );
  };

  return (
    <>
      <div
        data-testid='user-details'
        onClick={toggleUserData}
        styleName={`user-details ${props.customCssClasses}`}
        className='login-user-details'
      >
        {fallbackImage()}
        {showUserData && (
          <div styleName='overlay'>
            <div styleName='user-data' className='user-data'>
              <ul>
                <li>
                  <CustomLink
                    href={getPath({
                      sketchesHost,
                      relativePath: '/user-details',
                      injectCustomParams: true,
                      whiteListedUrlParams
                    })}
                    callback={toggleUserData}
                  >
                    {profilePageText || 'My Profile'}
                  </CustomLink>
                </li>
                {accesstypeEnabled && (
                  <>
                    {enablePayPerAssetEnabled && (
                      <li>
                        <CustomLink
                          href={getPath({
                            sketchesHost,
                            relativePath: '/purchased-stories',
                            injectCustomParams: true,
                            whiteListedUrlParams
                          })}
                          callback={toggleUserData}
                        >
                          {purchasedPageText || 'Purchased Stories'}
                        </CustomLink>
                      </li>
                    )}
                    <li>
                      <CustomLink href={manageSubsLink}>
                        {textWithFallback(manageSubscriptionsText, 'Manage Subscriptions')}
                      </CustomLink>
                    </li>
                  </>
                )}
                <li>
                  <div>
                    <a href={'#'} onClick={event => logoutHandler(event)}>
                      {localizedSignOut || 'Sign out'}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function UserDataTemplate (props) {
  const [userLoaded, setUserLoaded] = useState(false);
  const email = get(props, ['member', 'email']);
  const phone = get(props, ['member', 'metadata', 'phone-number'], '1234');
  const PbAccessTypeConfig = get(props, ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration'], {});
  const isStaging = get(PbAccessTypeConfig, ['accesstypeMode']) === 'staging';
  const {
    accesstypeKey = '',
    enableAccesstype = false,
    accessTypeProdHost = '',
    accessTypeStgHost = '',
    bridgeKeeperIntegrationId = ''
  } = PbAccessTypeConfig;
  const sketchesHost = get(props, ['config', 'sketches-host'], '');
  const storyId = get(props, ['story', 'id']);
  
  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={isStaging}
      accessTypeKey={accesstypeKey} // "9rm1ijqQmGfJ2kwQ9zt2gWhK"
      accessTypeBkIntegrationId={bridgeKeeperIntegrationId}
      email={email}
      sketchesHost={sketchesHost}
      phone={phone}
      storyId={storyId}
      prodHost={accessTypeProdHost}
      stagingHost={accessTypeStgHost}
      onUserSet={() => {
        setUserLoaded(true);
      }}
    >
      {({ getSubscriptionForUser }) => (
        <UserDataBase getSubscriptionForUser={getSubscriptionForUser} userLoaded={userLoaded} {...props} />
      )}
    </AccessType>
  );
}

UserDataBase.propTypes = {
  member: PropTypes.object,
  logout: PropTypes.func,
  subscriptions: PropTypes.array,
  getSubscriptionForUser: PropTypes.func,
  updateMemberSubscriptions: PropTypes.func,
  memberSubscriptions: PropTypes.array,
  customCssClasses: PropTypes.string,
  updateMember: PropTypes.func
};

const mapStateToProps = state => {
  return {
    config: get(state, ['qt', 'config']),
    member: get(state, ['member'], null),
    subscriptions: get(state, ['subscriptions'], []),
    memberSubscriptions: get(state, ['memberSubscriptions'], [])
  };
};

const mapDispatchToProps = dispatch => ({
  updateMemberSubscriptions: subscriptions => dispatch({ type: 'MEMBER_SUBSCRIPTIONS_UPDATED', subscriptions }),
  memberSubscriptionsLoaded: () => dispatch({ type: 'MEMBER_SUBSCRIPTIONS_LOADED' }),
  updateMember: member => dispatch({ type: 'MEMBER_UPDATED', member })
});

export const UserData = connect(mapStateToProps, mapDispatchToProps)(UserDataTemplate);