/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  PhotoStoryTemplates,
  LiveBlogStoryTemplate,
  TextStoryTemplate,
  ListicleStoryTemplate,
  VideoStoryTemplate,
} from "@quintype/arrow";

import { useDispatch } from "react-redux";
import { handleStoryCardsAccess } from "../../utils";
import { UpdateOnInterval } from "@quintype/components";
import PaywallBanner from "../basic/request-subscription/paywall-banner";
import { getStoryDataWithAdSlots } from "./get-story";
import { StoryFooter } from "../molecules/story-footer";
import {
  visibleCardsRender,
  getUser,
  update_AT_WithUserInfo,
  getAccessTypeScript,
  getMeterIndicatorConfig,
  MeteringIndicator,
} from "./util";
import "./paywall-story-styles.m.css";

const PaywallStoryAccessWrapper = ({
  story,
  config,
  templateConfig,
  preview,
  storyElementsConfig,
  adComponent,
  widgetComp,
  subPageType,
  enableDarkMode,
  loadRelatedStories,
  isPbStoryView = false,
}) => {
  const initialAuthorization = handleStoryCardsAccess({ access: null, accessLoading: true, config });
  const initialPaywallTemplateConfig = Object.assign({}, templateConfig, {
    noOfVisibleCards: templateConfig?.pageBuilderVisibleCards || initialAuthorization?.visibleCards,
  });

  const PbAccessTypeConfig = get(config, ["pagebuilder-config", "general", "accesstypeIntegration"], {});
  const {
    accesstypeKey = "",
    bridgeKeeperIntegrationId = "",
    enableAccesstype = false,
    disableMetering = true,
    accessTypeProdHost = "",
    accessTypeStgHost = "",
    accesstypeMode = "",
    enablePayPerAsset = false,
  } = PbAccessTypeConfig;

  const isStaging = accesstypeMode === "staging";

  const [storyAccess, setStoryAccess] = useState({
    accessGranted: null,
    authorization: initialAuthorization,
    paywallTemplateConfig: initialPaywallTemplateConfig,
    grantDescription: "",
    meterIndicatorConfig: {},
  });

  const dispatch = useDispatch();

  async function checkStoryAccess() {
    try {
      const sketchesHost = get(config, ["sketches-host"], "");
      const asset = { id: story?.id, type: "story" };
      const user = await getUser(bridgeKeeperIntegrationId, sketchesHost);
      await update_AT_WithUserInfo(user);

      const updatedAccess = (await window.AccessType.isAssetAccessible(asset, disableMetering)) || {};
      const { grantReason, granted, data } = updatedAccess;

      if (enablePayPerAsset && !granted) {
        const assetPlans = await window.AccessType.getAssetPlans(asset);
        dispatch({ type: "QT-ASSET_PLANS", assetPlans });
      }

      let meterIndicatorConfig = {};

      if (!disableMetering && granted && grantReason === "METERING") {
        await window.AccessType.pingbackAssetAccess(asset, updatedAccess);
        const meterCount = data?.numberRemaining || -1;
        meterIndicatorConfig = getMeterIndicatorConfig(user, story, config, meterCount);
      }

      const updatedAuthorization = handleStoryCardsAccess({ access: updatedAccess, accessLoading: false, config });
      const UpdatedPaywallTemplateConfig = Object.assign({}, templateConfig, {
        noOfVisibleCards: templateConfig?.pageBuilderVisibleCards || updatedAuthorization?.visibleCards,
      });
      return {
        accessGranted: granted,
        authorization: updatedAuthorization,
        paywallTemplateConfig: UpdatedPaywallTemplateConfig,
        grantDescription: data?.grantDescription,
        meterIndicatorConfig,
      };
    } catch (error) {
      console.log("error checking story Access", error);
      return {
        accessGranted: false,
        authorization: initialAuthorization,
        paywallTemplateConfig: initialPaywallTemplateConfig,
        grantDescription: "",
        meterIndicatorConfig: {},
      };
    }
  }

  useEffect(() => {
    const accessTypeScript = getAccessTypeScript(isStaging, accessTypeStgHost, accessTypeProdHost, accesstypeKey);
    accessTypeScript.onload = async () => {
      const access = await checkStoryAccess();
      setStoryAccess(access);
    };
    document.body.appendChild(accessTypeScript);
  }, []);

  const isInfiniteScrollEnabled = get(templateConfig, ["isInfiniteScrollEnabled"]);

  const wrapper =
    (StoryComponent) =>
    ({
      story,
      paywallTemplateConfig,
      storyElementsConfig,
      adComponent,
      widgetComp,
      enableDarkMode,
      loadRelatedStories,
    }) => {
      const { enableMeteringIndicator = false, meteringMsg = "" } = storyAccess?.meterIndicatorConfig;
      return (
        <StoryComponent
          storyAccess={storyAccess}
          story={story}
          config={paywallTemplateConfig}
          storyElementsConfig={storyElementsConfig}
          adComponent={adComponent}
          widgetComp={widgetComp}
          enableDarkMode={enableDarkMode}
          loadRelatedStories={loadRelatedStories}
          firstChild={
            storyAccess.authorization?.isPaywallEnabled && (
              <PaywallBanner
                story={story}
                accesstypeKey={accesstypeKey}
                storyAccess={storyAccess}
                disableMetering={disableMetering}
                enableDarkMode={enableDarkMode}
                enableAccesstype={enableAccesstype}
                accessTypeProdHost={accessTypeProdHost}
                accessTypeStgHost={accessTypeStgHost}
                storyId={story?.id}
                isStaging={isStaging}
                bridgeKeeperIntegrationId={bridgeKeeperIntegrationId}
              />
            )
          }
          secondChild={!preview && <StoryFooter story={story} isInfiniteScrollEnabled={isInfiniteScrollEnabled} />}
          visibleCardsRender={visibleCardsRender}
          meteringIndicator={
            enableMeteringIndicator && <MeteringIndicator meteringMsg={meteringMsg} enableDarkMode={enableDarkMode} />
          }
          isPbStoryView={isPbStoryView}
        />
      );
    };

  const StoryTemplate = ({
    subPageType,
    story,
    paywallTemplateConfig,
    storyElementsConfig,
    adComponent,
    widgetComp,
    enableDarkMode,
    loadRelatedStories,
  }) => {
    switch (subPageType) {
      case "video":
        return wrapper(VideoStoryTemplate)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
      case "photo":
        return wrapper(PhotoStoryTemplates)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
      case "text":
        return wrapper(TextStoryTemplate)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
      case "listicle":
        return wrapper(ListicleStoryTemplate)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
      case "live-blog":
        return wrapper(LiveBlogStoryTemplate)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
      default:
        return wrapper(TextStoryTemplate)({
          story,
          paywallTemplateConfig,
          storyElementsConfig,
          adComponent,
          widgetComp,
          enableDarkMode,
          loadRelatedStories,
        });
    }
  };

  return StoryTemplate({
    subPageType,
    story,
    paywallTemplateConfig: isPbStoryView ? initialPaywallTemplateConfig : storyAccess.paywallTemplateConfig, // this will let pb story-UI update on layout change
    storyElementsConfig,
    adComponent,
    widgetComp,
    enableDarkMode,
    loadRelatedStories,
  });
};

PaywallStoryAccessWrapper.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  checkAccess: PropTypes.func,
  accessUpdated: PropTypes.func,
  initAccessType: PropTypes.func,
  accessIsLoading: PropTypes.func,
  initRazorPayPayment: PropTypes.func,
  getSubscriptionForUser: PropTypes.func,
  accessLoading: PropTypes.bool,
  templateConfig: PropTypes.object,
  storyElementsConfig: PropTypes.object,
  adComponent: PropTypes.func,
  widgetComp: PropTypes.func,
};

const PaywallStory = ({
  config,
  story,
  templateConfig,
  storyElementsConfig,
  adComponent,
  widgetComp,
  storyBaseType,
  enableDarkMode,
  loadRelatedStories,
  isPbStoryView = false,
}) => {
  const subPageType = storyBaseType || story["story-template"];
  const sketchesHost = get(config, ["sketches-host"], "");

  const storyId = get(story, ["id"], "");
  const { storySlots } = templateConfig;
  const storyDataLoader = () => getStoryDataWithAdSlots(storyId, storySlots, sketchesHost);

  if (subPageType === "live-blog") {
    return (
      <UpdateOnInterval dataLoader={storyDataLoader} interval={20000} initData={{ story: story }}>
        {({ data }) => {
          const { story } = data;
          return (
            <PaywallStoryAccessWrapper
              story={story}
              config={config}
              templateConfig={templateConfig}
              storyElementsConfig={storyElementsConfig}
              adComponent={adComponent}
              widgetComp={widgetComp}
              subPageType={subPageType}
              enableDarkMode={enableDarkMode}
              loadRelatedStories={loadRelatedStories}
              isPbStoryView={isPbStoryView}
            />
          );
        }}
      </UpdateOnInterval>
    );
  } else {
    return (
      <PaywallStoryAccessWrapper
        story={story}
        config={config}
        templateConfig={templateConfig}
        storyElementsConfig={storyElementsConfig}
        adComponent={adComponent}
        widgetComp={widgetComp}
        subPageType={subPageType}
        enableDarkMode={enableDarkMode}
        loadRelatedStories={loadRelatedStories}
        isPbStoryView={isPbStoryView}
      />
    );
  }
};

PaywallStory.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  templateConfig: PropTypes.object,
  storyElementsConfig: PropTypes.object,
  adComponent: PropTypes.func,
  widgetComp: PropTypes.func,
  storyBaseType: PropTypes.string,
};

export default PaywallStory;
