/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { connect } from 'react-redux';
import { defaultTheme, defaultDarkTheme } from '../constants';
import { getLuminanceOfColor } from '../utils';
import get from 'lodash/get';

function hexToRGB(hex, alpha) {
  if (hex.length < 7) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
}

function getRootVariables(props) {
  const pageBuilderConfig = props.pageBuilderConfig;
  const { darkPrimaryColor = '#4860BC', primaryColor = '#4860BC' } = get(props, ['pageBuilderConfig', 'general'], {});
  const getDarkPrimaryColor = darkPrimaryColor || primaryColor;
  const pbPrimaryColor = props.enableDarkMode ? getDarkPrimaryColor : primaryColor;
  const { header = {}, footer = {}, general = {} } = pageBuilderConfig || {};

  const fallbackFont = '-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell';
  // TODO: move the default values to db config
  // remove lineHeightVariables and update variable mapping

  const themeVariables = {
    '--modeColor': props.enableDarkMode ? '#fff' : '#333',
    '--headlineLineHeight': '1.4',
    '--bodyLineHeight': '1.5',
    '--bleedCarouseltitleLineHeight': '1.4',
    '--primaryHeaderBackgroundColor': props.enableDarkMode
      ? get(header, ['darkPrimaryBackgroundColor'], '#292929')
      : get(header, ['primaryBackgroundColor'], '#fff'),
    '--primaryHeaderTextColor': props.enableDarkMode
      ? get(header, ['darkPrimaryTextColor'], defaultTheme)
      : get(header, ['primaryTextColor'], '#000'),
    '--secondaryHeaderTextColor': props.enableDarkMode
      ? get(header, ['darkSecondaryTextColor'], defaultTheme)
      : get(header, ['secondaryTextColor'], '#000'),
    '--secondaryHeaderBackgroundColor': props.enableDarkMode
      ? get(header, ['darkSecondaryBackgroundColor'], '#303030')
      : get(header, ['secondaryBackgroundColor'], '#fff'),
    '--hamburgerAndMegaMenuBgColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['header', 'hamburgerAndMegamenuDarkBackgroundColor'], defaultDarkTheme)
      : get(pageBuilderConfig, ['header', 'hamburgerAndMegamenuBackgroundColor'], '#fff'),
    '--hamburgerAndMegaMenuTextColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['header', 'hamburgerAndMegamenuDarkTextColor'], defaultTheme)
      : get(pageBuilderConfig, ['header', 'hamburgerAndMegamenuTextColor'], '#000'),
    '--footerTextColor': props.enableDarkMode
      ? get(footer, ['darkTextColor'], defaultTheme)
      : get(footer, ['textColor'], '#fff'),
    '--footerBackgroundColor': props.enableDarkMode
      ? get(footer, ['darkBackgroundColor'], '#2d2d2d')
      : get(footer, ['backgroundColor'], '#000'),
    '--breakingNewsBgColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['breakingNews', 'darkBackgroundColor'], '#2d2d2d')
      : get(pageBuilderConfig, ['breakingNews', 'backgroundColor'], '#EFEFEF'),
    '--breakingNewsTextColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['breakingNews', 'darkTextColor'], defaultTheme)
      : get(pageBuilderConfig, ['breakingNews', 'textColor'], '#333'),
    '--subscription_color': props.enableDarkMode
      ? get(
        pageBuilderConfig,
        ['subscription', 'pageSetting', 'darkButtonColor'],
        get(pageBuilderConfig, ['general', 'accesstypeIntegration', 'darkSubscriptionColor'], pbPrimaryColor)
      )
      : get(
        pageBuilderConfig,
        ['subscription', 'pageSetting', 'buttonColor'],
        get(pageBuilderConfig, ['general', 'accesstypeIntegration', 'subscriptionColor'], pbPrimaryColor)
      ),
    '--placeholderBackground': props.enableDarkMode ? '#303030' : '#f4f5f7',
    '--primaryFont': fallbackFont,
    '--secondaryFont': fallbackFont,
    '--bleedCarouselSectionColor': '#fff',
    '--bleedCarouselSectionBackgroundColor': 'rgba(216,37,28,.7)',
    '--bleedCarouselTitleColor': '#fff',
    '--bleedCarouselTitleBackgroundColor': 'transparent',
    '--bleedCarouselAuthorColor': '#D4D4D4',
    '--bleedCarouselAuthorBackgroundColor': 'transparent',
    '--primaryColorRgba': hexToRGB(pbPrimaryColor || '#000000').join(', '),
    '--primaryHeaderTextColorRgba': hexToRGB(
      props.enableDarkMode ? get(header, ['darkPrimaryTextColor'], '#fff') : get(header, ['primaryTextColor'], '#000')
    ).join(', '),
    '--secondaryHeaderTextColorRgba': hexToRGB(
      props.enableDarkMode
        ? get(header, ['darkSecondaryTextColor'], '#000')
        : get(header, ['secondaryTextColor'], '#000')
    ).join(', '),
    '--hamburgerAndMegaMenuTextColorRgba': hexToRGB(
      props.enableDarkMode ? '#fff' : get(header, ['hamburgerAndMegamenuTextColor'], '#fff')
    ).join(', '),
    '--halfWidthCarouselHeadingColor': '#333333',
    '--halfWidthCarouselDescriptionColor': '#4c4c4c',
    '--halfWidthCarouselAuthorColor': '#515151',
    '--halfWidthCarouselheadlineLineHeight': '1.2',
    // Hyperlink color inside Text story element
    '--textElementHyperlinkColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['story', 'globalStoryElements', 'text', 'css', 'darkHyperlinkColor'], '#2f81cd')
      : get(pageBuilderConfig, ['story', 'globalStoryElements', 'text', 'css', 'hyperlinkColor'], '#2f81cd'),
    '--headerBackgroundColor': '#fff',
    '--headerIconColor': '#a9a9a9',
    '--headerTextColor': '#333',
    '--primaryColor': pbPrimaryColor || '#000',
    '--primaryContrastColor': getLuminanceOfColor(primaryColor) < 155 ? '#FFFFFF' : '#000000',
    '--textColor': props.enableDarkMode ? defaultTheme : '#333',
    '--defaultBgColor': props.enableDarkMode ? '#3b3b3b' : defaultTheme,
    '--staticHeadlineColor': props.enableDarkMode ? '#FDFDFD' : '#0D0D0D',
    '--staticTextColor': props.enableDarkMode ? '#E9E9E9' : '#636363',
    '--staticInfoColor': props.enableDarkMode ? '#797979' : '#D1D1D1',
    '--standardBgColor': props.enableDarkMode ? defaultDarkTheme : defaultTheme,
    '--loginButtonColor': props.enableDarkMode
      ? get(pageBuilderConfig, ['login', 'pageSetting', 'darkButtonColor'], darkPrimaryColor || '#4860BC')
      : get(pageBuilderConfig, ['login', 'pageSetting', 'buttonColor'], primaryColor),
    '--nxtHeaderBackgroundColor': get(general, ['nextConfig', 'header', 'backgroundColor']),
    // Generic styles for custom pages
    '--customPageCardColor': props.enableDarkMode ? '#262626;' : '#FFFFFF',
    '--customPageTagColor': props.enableDarkMode ? '#FFFFFF0A;' : '#0000000A',
    '--customPageBorderColor': props.enableDarkMode ? '#FFFFFF0A' : '#00000029'
  };
  return themeVariables;
}

function getRootVariablesString(props) {
  const rootVariables = getRootVariables(props);
  let rootVariablesString = '';
  Object.keys(rootVariables).forEach((key) => (rootVariablesString += `${key}: ${rootVariables[key]};\n`));
  return rootVariablesString;
}

// TODO: Remove existing Themebase logic after Madrid Header Rewrite
function ThemeBase(props) {
  const { darkPrimaryColor, primaryColor = '#4860BC' } = get(props, ['pageBuilderConfig', 'general'], {});
  const getDarkPrimaryColor = darkPrimaryColor || primaryColor;

  const pbPrimaryColor = props.enableDarkMode ? getDarkPrimaryColor : primaryColor;

  return `.qt-theme__color {
        color: ${pbPrimaryColor || 'initial'} !important;
      }
      .qt-theme__color--border {
        border-color: ${pbPrimaryColor || 'unset'} !important;
      }
      .blank-story .story-element-text a:after {
        background-color: ${pbPrimaryColor || 'initial'} !important;
      }
      .card--align-left .story-element-text-quote,
      .card--align-left .story-element-text-blockquote,
      .card--align-left .story-element-text-blurb,
      .card--align-right .story-element-text-quote,
      .card--align-right .story-element-text-blockquote,
      .card--align-right .story-element-text-blurb {
        border-color: ${pbPrimaryColor || 'unset'} !important;
      }
      ::-moz-selection {
             background-color: ${pbPrimaryColor || '#2f73e4'};
             color: #FFF;
             opacity: 0.5;
      }
      ::selection {
             background-color: ${pbPrimaryColor || '#2f73e4'};
             color: #FFF;
             opacity: 0.5;
      }
      :root {${getRootVariablesString(props)}}`;
}

function mapStateToProps(state) {
  return {
    enableDarkMode: get(state, ['header', 'isDarkModeEnabled'], false),
    pageBuilderConfig: state.qt.config['pagebuilder-config'] || {}
  };
}

export const Theme = connect(mapStateToProps, null)(ThemeBase);
