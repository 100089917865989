/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./user-profile.m.css";
import { SvgHandler } from "../svg-handler/svg-handler";
import defaultAuthorIcon from "../../../assets/icons/user-icon.svg";
import { UserData } from "./user-profile-data";
import { getLoginLink } from "../pages/login/sso-utils";
import get from "lodash/get";

export const UserProfile = ({ sso, member, customCssClasses = "", localizedSignIn, sketchesHost, whiteListedUrlParams }) => {
  useEffect(() => {
    global?.addEventListener("message", metypeEventHandler);
  }, []);

  const metypeEventHandler = (event) => {
    const action = get(event, ["data", "action"]);
    if (action === "metype_login") {
      getLoginLink(member, sso, sketchesHost, whiteListedUrlParams);
    }
  };

  if (!member?.["verification-status"]) {
    return (
      <div
        onClick={() => getLoginLink(member, sso, sketchesHost, whiteListedUrlParams)}
        styleName={`sign-btn ${customCssClasses}`}
        data-testid="sign-in-btn"
      >
        <span styleName="white-space" data-test-id="user-profile" className="sign-in-btn">
          <div styleName="sign-in-svg" className="sign-in-svg" data-testid="sign-in-svg">
            <SvgHandler
              xlinkHref={defaultAuthorIcon}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              svgWrapperClazzName="svg-wrapper"
            />
          </div>
          <div styleName="sign-in-btn" className="sign-in-button" data-testid="sign-in-button">
            {localizedSignIn || "Sign in"}
          </div>
        </span>
      </div>
    );
  } else {
    return <UserData customCssClasses={customCssClasses} />;
  }
};

UserProfile.propTypes = {
  member: PropTypes.object,
  logout: PropTypes.func,
  customCssClasses: PropTypes.string,
  localizedSignIn: PropTypes.string,
  sso: PropTypes.shape({
    enableSingleSignOn: PropTypes.bool,
    bridgeKeeperClientId: PropTypes.string,
  })
};
