/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import './paywall-story-styles.m.css';
import get from 'lodash/get';
import loadingIcon from '../../../assets/icons/loading.svg';
import { SvgHandler } from '../svg-handler/svg-handler';
import {
  STAGING_AT_HOST,
  PROD_AT_HOST,
  defaultMeteringSingularMessage,
  defaultMeteringPluralMessage
} from '../../constants';
import React from 'react';
import { getPath } from '../../utils';

export const visibleCardsRender = (visibleCards, firstChild = null, access) => {
  const accessGranted = access?.accessGranted;
  const accessLoading = access?.accessGranted === null;
  return (
    <>
      <div className='paywall' styleName={accessGranted ? '' : 'visible-cards-wrapper'}>
        {visibleCards}
      </div>
      {accessLoading ? (
        <div>
          <div styleName='loading-message'>Loading content, please wait...</div>
          <div styleName='loader-icon'>
            <SvgHandler xlinkHref={loadingIcon} />
          </div>
        </div>
      ) : (
        firstChild
      )}
    </>
  );
};

export function getAccessTypeScript (isStaging, accessTypeStgHost, accessTypeProdHost, accesstypeKey) {
  const HOST = isStaging ? accessTypeStgHost || STAGING_AT_HOST : accessTypeProdHost || PROD_AT_HOST;
  const environment = isStaging ? '&env=sandbox' : '';
  const accessTypeHost = `${HOST}/frontend/v2/accesstype.js?key=${accesstypeKey}${environment}`;
  const accessTypeScript = document.createElement('script');
  accessTypeScript.setAttribute('src', accessTypeHost);
  accessTypeScript.setAttribute('id', 'AccessTypeScript');
  accessTypeScript.setAttribute('data-accessType-script', '1');
  accessTypeScript.async = 1;
  return accessTypeScript;
}

export async function getUser (bridgeKeeperIntegrationId, sketchesHost) {
  try {
    const accessTokenUrl = getPath({
      sketchesHost,
      relativePath: `/api/auth/v1/access-token/integrations/${bridgeKeeperIntegrationId}`
    });
    const bridgeKeeperResponse = await fetch(accessTokenUrl);
    const accessToken = bridgeKeeperResponse.headers.get('x-integration-token');
    const userIdentification = accessToken ? { accesstypeJwt: accessToken } : { isLoggedIn: false };
    return userIdentification;
  } catch (error) {
    throw new Error(`unable to authorize user, ${error}`);
  }
}

export async function update_AT_WithUserInfo (userIdentification) {
  try {
    await window.AccessType.setUser(userIdentification);
  } catch (error) {
    throw new Error(`unable to update AT with user info, ${error}`);
  }
}

export function getMeterIndicatorConfig (user, story, config, remainingStoryCount) {
  const userType = user?.accesstypeJwt ? 'loggedIn' : 'anonymous';
  const storyBehind = story?.access;
  let meterConfig = get(
    config,
    ['pagebuilder-config', 'meteredPaywallConfig', 'pageSetting', 'meteringOn', userType],
    {}
  );

  if (userType === 'anonymous') {
    switch (storyBehind) {
      case 'login':
        meterConfig = get(meterConfig, [storyBehind]);
        break;
      case 'subscription':
        const AT_wallType = get(meterConfig, ['subscription', 'AT_wallType'], 'payWall');
        meterConfig = get(meterConfig, [storyBehind, AT_wallType]);
    }
  }

  const {
    MeteringIndicator: enableMeteringIndicator = true,
    meteringMsgPlural = '',
    meteringMsgSingular = ''
  } = meterConfig || {};

  let meteringMsg = '';

  if (!enableMeteringIndicator || remainingStoryCount < 1) return { enableMeteringIndicator: false };
  if (remainingStoryCount === 1) meteringMsg = meteringMsgSingular || defaultMeteringSingularMessage;
  if (remainingStoryCount > 1) meteringMsg = meteringMsgPlural || defaultMeteringPluralMessage;

  meteringMsg = meteringMsg.replace('[number-of-stories]', remainingStoryCount);

  return {
    meteringMsg,
    enableMeteringIndicator
  };
}

export function MeteringIndicator ({ meteringMsg, enableDarkMode }) {
  const backgroundStyle = enableDarkMode ? 'meterIndicator-wrapper-dark' : 'meterIndicator-wrapper-light';
  return (
    <div styleName={backgroundStyle + ' meterIndicator-wrapper'}>
      <span styleName='meterIndicator-text'>{meteringMsg}</span>
    </div>
  );
}
