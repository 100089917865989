/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import format from 'date-fns/format';
import get from 'lodash/get';
import queryString from 'query-string';
import lowerCase from 'lodash/lowerCase';
import { breakpoint } from '../utils/breakpoint';
import canUseDom from '../utils/can-use-dom';
import { componentMetadata } from '../server/component-list';
import { QT_PAGE_DATA, QUILL_EMPTY_STRING } from './constants';

export function storyColor (config = {}, story = {}) {
  const sectionId = get(story, ['sections', 0, 'id']);
  const menu = get(config, ['layout', 'menu'], []);
  const menuItem = menu.find(menuItem => menuItem.type === 'section' && menuItem['item-id'] === sectionId);
  return get(menuItem, ['data', 'color'], '#000000');
}

export function sectionPath (config, section, domainSlug) {
  if (!section) return '';
  const sectionPathOnId = id => {
    const section = get(config, ['sections'], []).find(section => section.id === id);
    if (!section) return '';
    // if domainSlug is present and if we are on a subdomain, the root section is rendered on subdomain
    if (!section['parent-id'] && domainSlug) return '';
    return section['parent-id'] ? `${sectionPathOnId(section['parent-id'])}/${section.slug}` : section.slug;
  };

  const sectionPathString = sectionPathOnId(section.id);
  if (sectionPathString.startsWith('/')) return sectionPathString.slice(1);
  return sectionPathString;
}

export function getNumberOfStoriesToShow (numberOfStoriesToShow) {
  return numberOfStoriesToShow ? parseInt(numberOfStoriesToShow) : 6;
}

export function getNumberOfCollectionToShow (numberOfColletionsToShow) {
  return numberOfColletionsToShow ? parseInt(numberOfColletionsToShow) : 6;
}

export function altTagOfHeroImages (story = {}) {
  return story?.['hero-image-alt-text'] || story?.['hero-image-caption'] || story?.['headline'];
}

/**
 * Returns the URL for a news elsewhere or external story.
 * This is not a function that returns a boolean even though it is named this way.
 *
 * @param {Object} story The story object
 * @returns {string | boolean} Either the URL for a news elsewhere story or false
 */
export function isExternalStory (story = {}) {
  switch (story['story-template']) {
    case 'news-elsewhere':
      return get(story, ['metadata', 'reference-url'], '');
    default:
      return false;
  }
}

const convertStoriesIntoCollectionStructure = (metadata, stories = []) => {
  const getStoryData = stories.map(story => ({ story: story, type: 'story' }));
  return {
    type: 'collection',
    'associated-metadata': metadata,
    items: getStoryData,
    slug: '',
    name: ''
  };
};

export function collectionWithIntroductionCard (config, stories, pageType, data) {
  const layouts = get(config, ['pagebuilder-config', pageType, 'rows'], []);
  const collection = {
    items: layouts,
    type: 'collection',
    associatedMetatdata: {}
  };

  return pageBuilderRowWithIntroCard(layouts, collection, stories, data);
}

export function pageBuilderRowWithIntroCard (layouts = [], collection = {}, stories, data) {
  const newItems =
    collection.items &&
    collection.items.map((arr, index) => {
      const layoutType = get(layouts[index], ['type'], '');
      if (layoutType === 'ads' || layoutType === 'widget') {
        return {
          type: 'collection',
          'associated-metadata': {
            ...layouts[index]
          },
          items: []
        };
      }
      if (get(layouts[index], ['type']) === 'intro') {
        return {
          type: 'collection',
          'associated-metadata': {
            ...layouts[index]
          },
          props: { ...data },
          items: []
        };
      }
      if (get(layouts[index], ['type']) === 'collections') {
        return convertStoriesIntoCollectionStructure({ ...layouts[index] }, stories);
      }
      if (get(layouts[index], ['type']) === 'search') {
        return convertStoriesIntoCollectionStructure({ ...layouts[index] }, stories);
      }
    });
  return {
    ...collection,
    items: newItems
  };
}

export function overrideLayouts (config, collection, pageType) {
  const layouts = get(config, ['pagebuilder-config', pageType, 'rows']);
  if (!layouts) {
    return collection;
  }
  return addAssociatedMetadata(collection, layouts); // layoutDatatype === "collections"
}

export function addAssociatedMetadata (collection, layouts = []) {
  /*
   * This function iterates through all pagebuilder rows from the layouts
   * parameter and generates a new collection whose associated-metadata is
   * overridden.
   *
   * Cases:
   * 1. Number of pagebuilder rows are more than the items in the collection.
   *    For this case we keep on adding the ads or widgets and ignore collection rows
   *    from pagebuilder.
   *
   * 2. Number of items in the collection are more than the pagebuilder rows.
   *    For this case we add a defaultPbLayout for the remaining items in the
   *    collection.
   *
   *  Number of rows = Number of items in the collection + Number of ads/widgets
   */
  // variable to iterate through the collection items.
  let activeIndex = 0;
  const overriddenItems = [];
  const isAdOrWidget = type => ['ads', 'widget'].includes(type);

  const defaultPbLayout = {
    type: 'collections',
    layout: 'ArrowFourColGrid',
    layoutLabel: 'Arrow: Four Col',
    pageType: 'home'
  };

  for (const layout of layouts) {
    const item = collection.items[activeIndex];
    const layoutType = get(layout, ['type']);
    if (isAdOrWidget(layoutType)) {
      overriddenItems.push({
        type: 'collection',
        'associated-metadata': {
          ...layout
        },
        items: []
      });
    } else {
      if (activeIndex >= get(collection, ['items', 'length'], 0)) {
        // Case 1:
        continue;
      }
      overriddenItems.push({
        ...item,
        'associated-metadata': Object.assign({}, item['associated-metadata'], layout)
      });
      activeIndex++;
    }
  }

  // Case 2:
  while (activeIndex < get(collection, ['items', 'length'], 0)) {
    const item = collection.items[activeIndex];
    overriddenItems.push({
      ...item,
      'associated-metadata': Object.assign({}, item['associated-metadata'], defaultPbLayout)
    });
    activeIndex++;
  }

  return {
    ...collection,
    items: overriddenItems
  };
}

export function formatter (value, unit, suffix, date) {
  const showDateForUnits = ['day', 'week', 'month', 'year'];
  if ((unit === 'hour' && value > 23) || showDateForUnits.indexOf(unit) !== -1) {
    return format(new Date(date), 'dd MMM, yyyy');
  } else if (value > 1) {
    return `${value} ${unit}s ${suffix}`;
  }
  return `${value} ${unit} ${suffix}`;
}

export const getFilteredMagazineCollection = (mountAtPrefix, storyId) => {
  return fetch(`${mountAtPrefix}/api/v1/stories/${storyId}/attributes`)
    .then(response => response.json())
    .then(response => {
      const filteredMagazineCollection = response?.collections?.find(collection =>
        get(collection, ['metadata', 'entities', 'collectionEntities', 'magazine'])
      );
      const issueSlug = get(filteredMagazineCollection, ['slug'], '');
      const magazineSlug = get(
        filteredMagazineCollection,
        ['metadata', 'entities', 'collectionEntities', 'magazine', 0, 'slug'],
        ''
      );
      return { issueSlug, magazineSlug };
    })
    .catch(err => console.log('error', err));
};

export function getInfiniteScrollApi (infiniteScrollType, params, config, story) {
  const { mountAt } = config;
  const mountAtPrefix = mountAt || '';
  const FIELDS =
    'id,headline,metadata,read-time,bullet-type,slug,url,hero-image-s3-key,hero-image-metadata,first-published-at,last-published-at,updated-at,alternative,published-at,author-name,author-id,sections,story-template,cards,tags,authors,subheadline,access,story-audio,linked-stories,linked-story-ids,linked-entities,url,seo,hero-image-caption,hero-image-attribution,hero-image-hyperlink';
  return getFilteredMagazineCollection(mountAtPrefix, story.id).then(({ issueSlug, magazineSlug }) => {
    if (magazineSlug && issueSlug) {
      return `${mountAtPrefix}/api/v1/magazines/${magazineSlug}/issues/${issueSlug}?fields=${FIELDS}&limit=5&offset=${5 *
        params.pageNumber}`;
    } else {
      switch (infiniteScrollType) {
        case 'collection-based':
          return `${mountAtPrefix}/api/v1/collections/${
            params.collectionSlug
          }?item-type=story&fields=${FIELDS}&limit=5&offset=${5 * params.pageNumber}`;
        case 'section-based':
          return `${mountAtPrefix}/api/v1/stories?section-id=${params.sectionId}&fields=${FIELDS}&limit=5&offset=${5 *
            params.pageNumber}`;
        default:
          return `${mountAtPrefix}/api/v1/collections/curated-story-list?item-type=story&item-fields=${FIELDS}&limit=5&offset=${5 *
            params.pageNumber}`;
      }
    }
  });
}

export class LocalStorage {
  set (key, value) {
    global.localStorage && global.localStorage.setItem(key, JSON.stringify(value));
  }

  get (key) {
    return global.localStorage ? JSON.parse(global.localStorage.getItem(key)) : {};
  }

  remove (key) {
    global.localStorage && global.localStorage.removeItem(key);
  }
}

export function setSubscriptionRedirect (redirectPath = '/subscription', sketchesHost, whiteListedUrlParams = []) {
  let urlPath = redirectPath;
  if (sketchesHost) {
    urlPath = new URL(redirectPath, sketchesHost);
  }
  // appending callback origin location post success
  let redirectUrl = window?.location?.href || '';
  const currentUrl = new URL(redirectUrl);

  if (currentUrl?.search?.includes('redirectUrl')) {
    // Remove 'redirectUrl' from the current URL
    currentUrl.searchParams.delete('redirectUrl');
    redirectUrl = currentUrl.toString();
  } else {
    // this logic removes the garbage values in params
    const urlQueryParams = currentUrl?.search?.replace(/^\?/, '').split('&');
    const matchingParams = urlQueryParams.filter(param => whiteListedUrlParams.includes(param));
    const additionalQueryParams = matchingParams?.length ? `?${matchingParams.join('&')}` : '';
    redirectUrl = currentUrl ? `${currentUrl.origin}${currentUrl.pathname}${additionalQueryParams}` : '/';
  }

  urlPath.searchParams.set('redirectUrl', redirectUrl);
  // redirecting to new location
  window.location = urlPath;
}

export function getSubscriptionRedirect () {
  const paramsURL = window?.location?.search;
  const urlParams = new URLSearchParams(paramsURL);
  const redirectUrl = urlParams.get('redirectUrl');
  return redirectUrl;
}

export const awaitHelper = promise =>
  promise
    .then(data => ({
      data,
      error: null
    }))
    .catch(error => ({
      error,
      data: null
    }));

export function getHorizontalSocialShare (config = {}) {
  const socialShareStyle = 'horizontal-grey';

  return socialShareStyle.includes('vertical') ? socialShareStyle.replace('vertical', 'horizontal') : socialShareStyle;
}

export function getStoryTags (story = {}) {
  return story.tags
    .reduce((acc, item) => {
      acc.push(item.name);
      return acc;
    }, [])
    .toString();
}

export function getSocialShareProps (config = {}, story = {}) {
  return {
    url: story.slug,
    title: !isEmpty(story.summary) ? story.summary : story.headline,
    primaryColor: '',
    hashtags: getStoryTags(story)
  };
}

export function updateBodyScroll (removeScroll = false, selectorName = 'remove-body-scroll') {
  if (canUseDom) {
    const element = document.getElementsByTagName('body')[0];
    return removeScroll ? element.classList.add(selectorName) : element.classList.remove(selectorName);
  }
}

export function isEmpty (value) {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function pick (object, keys) {
  return keys.reduce((obj, key) => {
    if (object[key]) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

export function debounce (delay, fn) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export function isFacebookApp () {
  if (global.navigator) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1;
  }
  return false;
}

export function isEager (index, allCardsEager) {
  const isFirstCollection = index === 0;

  if (allCardsEager) return isFirstCollection;
  return isFirstCollection && !breakpoint('tablet');
}

export const getOverride = defaultValue => ({
  get: (obj, prop) => (prop in obj ? obj[prop] : defaultValue)
});

export const getScrollSpeed = (associatedMetadata = {}) => {
  const autoplayDuration = associatedMetadata['autoplay_duration_sec'];
  const scrollSpeedMs = associatedMetadata['scrollSpeedMs'];
  const setScrollSpeed = associatedMetadata['set_scroll_speed'];

  return (
    parseInt(autoplayDuration) * 1000 || parseInt(scrollSpeedMs) || parseInt(setScrollSpeed) * 10 || false // Note : Preserve priority of `OR` operations
  );
};

export const selectedStoryTemplate = (storyType, config = {}, storyStoreField = 'story') => {
  const template = get(config, ['pagebuilder-config', storyStoreField, storyType, 'template'], 'default');
  return template;
};

export const selectedStoryTemplateSettings = (storyType, config, storyStoreField = 'story') => {
  if (storyType === 'visualStory-story') {
    return get(config, ['pagebuilder-config', 'story', 'text-story', 'settings'], {});
  }
  const isPbEnabledSetting = get(config, ['pagebuilder-config', storyStoreField, storyType, 'settings'], {});
  return isPbEnabledSetting;
};

export const gridSelectorTemplate = (templateGridMap = {}, storyTemplate = '') => {
  const storyGridSelector = new Proxy(templateGridMap, getOverride('default'));
  return storyGridSelector[storyTemplate];
};

export const cloneScriptNode = (node = {}) => {
  const { innerHTML, attributes } = node;
  let script = document.createElement('script');
  script.text = innerHTML;
  script.async = true;
  const nodeAttributes = attributes && attributes.length;
  if (nodeAttributes) {
    for (let i = nodeAttributes - 1; i >= 0; i--) {
      const { name, value } = attributes[i];
      script.setAttribute(name, value);
    }
  }
  return script;
};

export const replaceScriptNodes = (node = {}) => {
  const { tagName, parentNode, childNodes = [] } = node;
  if (tagName === 'SCRIPT') {
    parentNode.replaceChild(cloneScriptNode(node), node);
  } else {
    let i = 0;
    while (i < childNodes.length) {
      replaceScriptNodes(childNodes[i++]);
    }
  }
};

export const getRowMetadata = (config, associatedMetadata) => {
  const pbConfig = get(config, ['pagebuilder-config']);
  if (pbConfig) {
    return associatedMetadata.config || associatedMetadata;
  }
  return associatedMetadata;
};

// updates the stories and checks for alternative image

export function replaceWithAlternates (story) {
  var alternates = get(story, ['alternative', 'home', 'default']);
  if (!alternates) return story;
  return Object.assign({}, story, {
    headline: alternates.headline || story.headline,
    'hero-image-s3-key': alternates['hero-image']
      ? alternates['hero-image']['hero-image-s3-key']
      : story['hero-image-s3-key'],
    'hero-image-metadata': alternates['hero-image']
      ? alternates['hero-image']['hero-image-metadata']
      : story['hero-image-metadata'],
    'hero-image-caption': alternates['hero-image']
      ? alternates['hero-image']['hero-image-caption']
      : story['hero-image-caption'],
    'hero-image-attribution': alternates['hero-image']
      ? alternates['hero-image']['hero-image-attribution']
      : story['hero-image-attribution']
  });
}

// updates the stories and checks for alternative info.
export function updateStoriesWithAlternatives (storiesList, isCollectionStories = true) {
  return storiesList.map(function (item) {
    const story = isCollectionStories ? item.story : item;
    return replaceWithAlternates(story);
  });
}

// Some utils from arrow as arrow is not exporting these functions.
export const hexToRGB = hex => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

export const getLuminanceOfColor = hex => {
  const { r, g, b } = hexToRGB(hex);
  return r * 0.299 + g * 0.587 + b * 0.114;
};

export const rgbToHex = (color = 'rgba(255, 255, 255, 0)') => {
  let rgba = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return rgba && rgba.length === 4
    ? '#' +
        ('0' + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[3], 10).toString(16)).slice(-2)
    : color;
};

export const getTextContrast = theme => {
  const isRegexCheck = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(theme);
  const getHexValue = !isRegexCheck ? rgbToHex(theme) : theme;
  const textColor = getLuminanceOfColor(getHexValue) < 155 ? 'light' : 'dark';
  return textColor;
};

export const handleStoryCardsAccess = ({ access, accessLoading, config }) => {
  const all = -1;
  const configCardCount = get(config, ['layout', 'no-of-visible-cards-in-a-blocked-story']) || 0;

  const isAccessGranted = get(access, ['granted'], false);

  if (isAccessGranted && !accessLoading) {
    const grantReason = get(access, ['grantReason']);

    switch (grantReason) {
      case 'METERING':
        const disableMetering = get(
          config,
          ['pagebuilder-config', 'general', 'accesstypeIntegration', 'disableMetering'],
          true
        );
        const numberRemaining = get(access, ['data', 'numberRemaining'], -1);
        if (!disableMetering || numberRemaining > -1) {
          return {
            isPaywallEnabled: false,
            visibleCards: all
          };
        } else {
          return {
            isPaywallEnabled: true,
            visibleCards: configCardCount
          };
        }

      case 'SUBSCRIBER':
        return {
          isPaywallEnabled: false,
          visibleCards: all
        };
    }
  }

  return {
    isPaywallEnabled: true,
    visibleCards: configCardCount
  };
};

export const getInfiniteScrollConfigStyle = config => {
  return get(config, ['pagebuilder-config', 'infiniteScroll', 'infiniteScrollStyle']);
};

export const getStoryUrl = (story, defaultValue = '') => {
  return get(story, ['story-template']) === 'news-elsewhere'
    ? get(story, ['metadata', 'reference-url'], '')
    : story.url || defaultValue;
};

export const getCollectionStories = async (collectionSlug, offset, limit, mountAtPrefix = '') => {
  try {
    const result = await fetch(
      `${mountAtPrefix}/api/v1/collections/${collectionSlug}?item-type=story&offset=${offset}&limit=${limit}`
    );
    return result.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAuthorStories = async (authorId, offset, limit, mountAtPrefix = '') => {
  try {
    const result = await fetch(
      `${mountAtPrefix}/api/v1/authors/${authorId}/collection?item-type=story&offset=${offset}&limit=${limit}`
    );
    return result.json();
  } catch (err) {
    console.error(err);
  }
};

export const tagPageStories = async (offset, limit, mountAtPrefix = '', tagSlugs, fields) => {
  try {
    const urlPath =
      fields.length === 0
        ? `/api/v1/stories?tag-slugs=${tagSlugs}&offset=${offset}&limit=${limit}`
        : `/api/v1/stories?tag-slugs=${tagSlugs}&offset=${offset}&limit=${limit}&fields=${fields}`;
    const result = await fetch(`${mountAtPrefix}${urlPath}`);
    return result.json();
  } catch (err) {
    console.error(err);
  }
};

export const filterCollectionStories = storyList => {
  return storyList.filter(item => item.type === 'story');
};

export const getDefaultStoriesCount = (layoutName = '') => {
  const component = componentMetadata.find(item => item.componentName === layoutName);
  if (!component) {
    return 0;
  }
  return component.storiesCount;
};

export const getQueryParam = (path = '', key) => {
  const query = path.split('?')[1];
  const queryObject = queryString.parse(query);
  return queryObject[key];
};

export const getLogoUrl = (qt = {}, layout = '') => {
  return get(qt, ['config', 'pagebuilder-config', layout, 'logoUrl']) || get(qt, ['currentHostUrl'], '/');
};

export const dateFormatter = (langCode = 'en', dateFormatType = 'mdy',  date= new Date()) => {
  let currentDate = '';
  const optionsDate = { year: 'numeric', month: 'short', day: '2-digit' };
  switch (dateFormatType) {
    case 'y-m-d':
      currentDate = date.toISOString().split('T')[0];
      break;
    case 'm/d/y':
      currentDate = date.toLocaleDateString('en-CA', { month: '2-digit', day: '2-digit', year: 'numeric' });
      break;
    case 'd/m/y':
      currentDate = date.toLocaleDateString('en-GB');
      break;
    case 'mdy':
      currentDate = date.toLocaleDateString(langCode, optionsDate);
      break;
  }
  return { currentDate };
};

export const timeFormatter = (timeFormatType = '12hours', date=new Date()) => {
  let currentTime = '';
  switch (timeFormatType) {
    case '24hours':
      currentTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      break;
    case '12hours':
      currentTime = date.toLocaleTimeString([], {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h12'
      });
      break;
  }
  return { currentTime };
};

export const scrollWindow = (element = false) => {
  if (element) {
    window.document.body.style.overflow = 'hidden';
  } else {
    window.document.body.style.overflow = '';
  }
};

export const maySetGlobals = data => {
  if (global && global.CustomEvent) {
    const customEvent = new CustomEvent(QT_PAGE_DATA, { detail: data });
    global.qtData = data;
    document.dispatchEvent(customEvent);
  }
};

export const getLocaleDate = (date, localizedMonths) => {
  const dateTime = new Date(date);
  const formatDate = format(dateTime, 'dd MMM yyyy');

  if (!localizedMonths) {
    return formatDate;
  }

  const splitDate = formatDate.split(' ');
  const month = splitDate[1];
  const lowerCaseMonth = lowerCase(month);
  const getLocalizedDate = get(localizedMonths, [lowerCaseMonth]) || month;
  splitDate[1] = getLocalizedDate;
  return splitDate.join(' ');
};

export const getCookie = cname => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(global.document.cookie);
  const splitDecodedCookie = decodedCookie.split('; ');
  const cookieValue = splitDecodedCookie.find(row => row.startsWith(name))?.split('=')[1];
  return cookieValue;
};

function addDefaultConfig (wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle) {
  const { wallTitle, wallDescription, signInTxt, buttonTxt, bottomTxt } = wallConfig || {};

  if (meteringDisabled) {
    switch (storyBehind) {
      case 'login':
        if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
          wallConfig.wallTitle = '<p><strong>Sign up to gain access to this article.</strong></p>';
        }
        if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
          wallConfig.wallDescription =
            '<p>To read more articles and enjoy an uninterrupted reading experience, please sign up with us.</p>';
        }
        if (!signInTxt) {
          wallConfig.signInTxt = 'Sign in';
        }
        if (!bottomTxt) {
          wallConfig.bottomTxt = 'Already have an account? [Sign in]';
        }

        return wallConfig;
      case 'subscription':
        if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
          wallConfig.wallTitle = '<p><strong>Subscribe to gain access to this premium article.</strong></p>';
        }
        if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
          wallConfig.wallDescription =
            '<p>To access our premium articles and enjoy an uninterrupted reading experience, please consider subscribing to one of our plans.</p>';
        }
        if (!signInTxt) {
          wallConfig.signInTxt = 'Sign in';
        }
        if (!bottomTxt) {
          wallConfig.bottomTxt = 'Already a subscriber? [Sign in]';
        }
        return wallConfig;
    }
  } else {
    if (userAnonymous) {
      switch (storyBehind) {
        case 'login':
          if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
            wallConfig.wallTitle = '<p><strong>Sign up for free to gain access to this article.</strong></p>';
          }
          if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
            wallConfig.wallDescription =
              '</p>You have exhausted your free articles for the day. To read more articles, please sign up with us.</p>';
          }
          if (!signInTxt) {
            wallConfig.signInTxt = 'Sign in';
          }
          if (!bottomTxt) {
            wallConfig.bottomTxt = 'Already have an account? [Sign in]';
          }
          return wallConfig;
        case 'subscription':
          if (pbWallTypeToggle === 'payWall') {
            if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
              wallConfig.wallTitle = '<p><strong>Subscribe to gain access to this premium article</strong></p>';
            }
            if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
              wallConfig.wallDescription =
                '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.</p>';
            }
            if (!signInTxt) {
              wallConfig.signInTxt = 'Sign in';
            }
            if (!bottomTxt) {
              wallConfig.bottomTxt = 'Already a subscriber?  [Sign in]';
            }
            return wallConfig;
          } else {
            if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
              wallConfig.wallTitle = '<p><strong>Sign up to gain access to this premium article.</strong></p>';
            }
            if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
              wallConfig.wallDescription =
                '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please sign up with us.</p>';
            }
            if (!signInTxt) {
              wallConfig.signInTxt = 'Sign in';
            }
            if (!bottomTxt) {
              wallConfig.bottomTxt = 'Already a user? [Sign in]';
            }
            return wallConfig;
          }
      }
    } else {
      if (!wallTitle || wallTitle === QUILL_EMPTY_STRING) {
        wallConfig.wallTitle = '<p><strong>Subscribe to gain access to this premium articles.</strong></p>';
      }
      if (!wallDescription || wallDescription === QUILL_EMPTY_STRING) {
        wallConfig.wallDescription =
          '<p>You have exhausted your free premium articles for the day. To access more premium content and enjoy uninterrupted reading experience, please consider subscribing to one of our plans.</p>';
      }
      return wallConfig;
    }
  }
}

export function getWallConfig (meteringDisabled, storyBehind, paywallSettings, userAnonymous, pbWallTypeToggle) {
  let wallConfig, buttonType;
  if (meteringDisabled) {
    switch (storyBehind) {
      case 'login':
        wallConfig = get(paywallSettings, ['meteringOff', 'login'], {});
        buttonType = 'signUp';
        wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
        return { wallConfig, buttonType };
      case 'subscription':
        wallConfig = get(paywallSettings, ['meteringOff', 'subscription'], {});
        buttonType = 'subscribe';
        wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
        return { wallConfig, buttonType };
    }
  } else {
    if (userAnonymous) {
      switch (storyBehind) {
        case 'login':
          wallConfig = get(paywallSettings, ['meteringOn', 'anonymous', 'login'], {});
          buttonType = 'signUp';
          wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
          return { wallConfig, buttonType };
        case 'subscription':
          if (pbWallTypeToggle === 'payWall') {
            wallConfig = get(paywallSettings, ['meteringOn', 'anonymous', 'subscription', 'payWall'], {});
            buttonType = 'subscribe';
            wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
            return { wallConfig, buttonType };
          } else {
            wallConfig = get(paywallSettings, ['meteringOn', 'anonymous', 'subscription', 'loginWall'], {});
            buttonType = 'signUp';
            wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
            return { wallConfig, buttonType };
          }
      }
    } else {
      wallConfig = get(paywallSettings, ['meteringOn', 'loggedIn'], {});
      buttonType = 'subscribe';
      wallConfig = addDefaultConfig(wallConfig, meteringDisabled, storyBehind, userAnonymous, pbWallTypeToggle);
      return { wallConfig, buttonType };
    }
  }
}

export function getCustomCssClasses (headerTheme) {
  switch (headerTheme) {
    case 'header_4':
      return 'header-four';
    case 'single-layer-header_4':
      return 'single-layer-header-four';
    case 'single-layer-header_5':
      return 'single-layer-header-five';
    default:
      return '';
  }
}

// Function to make an API call
const fetchData = async url => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch ${url}:`, error);
    throw error; // Rethrow the error to handle it in Promise.all
  }
};

export const getDynamicAssets = async (memberSubscriptions, offset, limit, loadType) => {
  let dynamicAssets = memberSubscriptions?.filter(plan => plan.subscription_type === 'dynamic_asset');
  if (loadType === 'SubsequentLoad') dynamicAssets = dynamicAssets.slice(offset, offset + Number(limit));
  if (dynamicAssets.length > 0) {
    try {
      const promises = dynamicAssets.map(asset => fetchData(`/api/v1/stories/${asset.dynamic_assets[0].id}`));
      const results = await Promise.all(promises);
      let updatedAssetStories = [...results.map(res => res.story)];
      if (loadType === 'SubsequentLoad') {
        updatedAssetStories = [
          ...results.map(res => {
            return { ...res, type: 'story' };
          })
        ];
      }
      return updatedAssetStories;
    } catch (error) {
      console.error('One or more API calls failed:', error);
    }
  }
  return [];
};

export const getPath = (props = {}) => {
  const {
    sketchesHost,
    relativePath,
    absolutePath = '',
    defaultParams = '',
    injectCustomParams = false,
    whiteListedUrlParams = [],
    swapDefaultCustomParams = false
  } = props;
  const getUrlParams = () => {
    if (whiteListedUrlParams?.length && injectCustomParams) {
      const currentUrl = window?.location?.href;
      const url = new URL(currentUrl);
      const urlQueryParams = url?.search?.replace(/^\?/, '').split('&');
      const matchingParams = urlQueryParams.filter(param => whiteListedUrlParams.includes(param));
      const additionalQueryParams = `${matchingParams.join('&')}`;
      const updatedParams = swapDefaultCustomParams
        ? [additionalQueryParams, defaultParams]
        : [defaultParams, additionalQueryParams];
      return updatedParams.filter(Boolean).join('&');
    }
    return defaultParams;
  };

  try {
    const params = getUrlParams();
    if (absolutePath) {
      return params ? `${absolutePath}?${params}` : absolutePath;
    }

    const { pathname } = new URL(sketchesHost);
    if (pathname && pathname !== '/') {
      return params ? `${sketchesHost}${relativePath}?${params}` : `${sketchesHost}${relativePath}`;
    }
    return params ? `${relativePath}?${params}` : relativePath;
  } catch (err) {
    console.log('Sketches host path error ---> ', err);
    return absolutePath ? absolutePath : relativePath;
  }
};


export function isLightRowsEnabled(pbConfig={}) {
  // forceEagerRowLoad is a toggle based eager loading of home page rows, Should be used only if needed.  
  const { lightRows = false, forceEagerRowLoad = false } = get(pbConfig, ["home"], {})
  return forceEagerRowLoad || lightRows;
}