/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { dateFormatter, timeFormatter } from "../../../utils";
import "./current-date-time.m.css";


function addCustomMeridiemLabels({ currentTime, timeFormat, enableLocalization, meridiem, direction, date}) {
  if (timeFormat === '12hours' && enableLocalization && direction == 'rtl') {
    const period = date?.getHours() >= 12 ? 'pm' : 'am';
    if (meridiem?.[period]) {
      const timeArr = currentTime.split(' ');
      timeArr[1] = meridiem?.[period];
      return timeArr.join(' ');
    }
  }
  return currentTime;
}

function addCustomMonthLabels({currentDate, enableLocalization, pbMonthCustomLabels, direction, dateFormat, date}) {
  try {
    if (enableLocalization && pbMonthCustomLabels  && dateFormat === "mdy" && direction == 'rtl') {
      const dateArray = currentDate.split(" ");
      const currentNumericMonth = date.getMonth() + 1;
      const monthMap = {1:"jan", 2:"feb", 3:"mar", 4:"apr", 5:"may", 6:"jun", 7:"jul", 8:"aug", 9:"sep", 10:"oct", 11:"nov", 12:"dec"};
      const customMonthLabel = pbMonthCustomLabels[monthMap[currentNumericMonth]];
      if(customMonthLabel) {
         dateArray[1] = customMonthLabel // extended for ltr with dateArray[0] = customMonthLabel;
      }
      return dateArray.join(" ");
    }
    return currentDate;
  } catch(e){
    return currentDate;
  }
} 

export const CurrentDateTime = () => {
  const { language = {}, "pagebuilder-config": pbConfig = {} } = useSelector((state) =>
    get(state, ["qt", "config"], {})
  );

  const { "ietf-code": langCode = "en", direction = "ltr" } = language;

  const { header = {}, general = {} } = pbConfig;
  const { enableTime = false, enableDate = false, dateFormat = "mdy" } = get(header, ["dateTime"], {});
  if (!enableDate && !enableTime) return null;

  const enableLocalization = get(general, ['localization', 'enableLocalization'], false);
  const { meridiem, months } = get(general, ['localization', 'localizedElements'], {});
  const timeFormat = get(general, ['timeFormat'], '12hours');
  const date = new Date();

  const { currentDate = "" } = dateFormatter(langCode, dateFormat, date, direction);
  const { currentTime = "" } = timeFormatter(general?.timeFormat, date);

  const currentTimeWithMeridiem = addCustomMeridiemLabels({currentTime, timeFormat, enableLocalization, meridiem, direction, date});
  const currentDateWithMonth = addCustomMonthLabels({currentDate, enableLocalization, pbMonthCustomLabels:months, direction, dateFormat, date});
  const [realTime, updateCurrentTime] = useState(currentTimeWithMeridiem);

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date()
      const { currentTime = "" } = timeFormatter(general?.timeFormat, date);
      const currentTimeWithMeridiem = addCustomMeridiemLabels({currentTime, timeFormat, enableLocalization, meridiem, direction, date});
      updateCurrentTime(currentTimeWithMeridiem);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (!enableDate && !enableTime) return null;

  return (
    <div styleName="wrapper">
      {enableDate && (
        <span styleName="current-date" data-testid="current-date">
          {currentDateWithMonth}
        </span>
      )}{' '}
      {enableTime && (
        <span styleName="current-time" data-testid="current-time">
          {realTime}
        </span>
      )}
    </div>
  );
};
