/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import './banner.m.css';
import { getLoginLink } from '../../pages/login/sso-utils';
import { getPath, setSubscriptionRedirect } from '../../../utils';
import { useSelector } from 'react-redux';
import get from 'lodash.get';

export function getPaywallButtons (
  buttonType,
  buttonTxt = '',
  buttonColor,
  buttonTextColor,
  member,
  sso,
  localizedMagazineSubscription,
  magazineSlug,
  isPbPreview
) {
  const primaryButtonStyle = 'banner-button banner-primary-button';
  const secondaryButtonStyle = 'banner-button banner-magazine-button';
  const sketchesHost = useSelector(state => get(state, ['qt', 'config', 'sketches-host'], ''));
  const whiteListedUrlParams = useSelector(state =>
    get(state, ['qt', 'config', 'pagebuilder-config', 'general', 'others', 'whiteListedUrlParams'], [])
  );
  if (isPbPreview) {
    return (
      <div
        className={primaryButtonStyle}
        styleName={primaryButtonStyle}
        style={{
          backgroundColor: buttonColor,
          color: buttonTextColor === 'dark' ? '#000' : '#fff'
        }}
      >
        {buttonTxt}
      </div>
    );
  }

  if (buttonType === 'subscribe') {
    const subscriptionUrl = getPath({
      sketchesHost,
      relativePath: `/subscription`,
      injectCustomParams: true,
      whiteListedUrlParams,
      
    });
    return (
      <div>
        {magazineSlug &&
          getMagazineSubcriptionButton(magazineSlug, localizedMagazineSubscription, secondaryButtonStyle)}
        <div
          styleName={primaryButtonStyle}
          style={{
            backgroundColor: buttonColor,
            color: buttonTextColor === 'dark' ? '#000' : '#fff'
          }}
          onClick={() => setSubscriptionRedirect(subscriptionUrl, sketchesHost, whiteListedUrlParams)}
        >
          {buttonTxt || 'Subscribe'}
        </div>
      </div>
    );
  }

  return (
    <div
      styleName={primaryButtonStyle}
      onClick={e => getLoginLink(member, sso, sketchesHost, whiteListedUrlParams)}
      style={{
        backgroundColor: buttonColor,
        color: buttonTextColor === 'dark' ? '#000' : '#fff'
      }}
    >
      {buttonTxt || 'Sign in'}
    </div>
  );
}

function getMagazineSubcriptionButton (magazineSlug, localizedMagazineSubscription, secondaryButtonStyle) {
  const sketchesHost = useSelector(state => get(state, ['qt', 'config', 'sketches-host'], ''));
  const whiteListedUrlParams = useSelector(state =>
    get(state, ['qt', 'config', 'pagebuilder-config', 'general', 'others', 'whiteListedUrlParams'], [])
  );
  const groupSubscriptionUrl = getPath({  
    sketchesHost,
    relativePath: `/subscription`,
    defaultParams: `group=${magazineSlug}`,
    injectCustomParams: true,
    whiteListedUrlParams
  });
  return (
    <div styleName={secondaryButtonStyle} onClick={() => setSubscriptionRedirect(groupSubscriptionUrl, sketchesHost, whiteListedUrlParams)}>
      {localizedMagazineSubscription || 'Magazine Subscription'}
    </div>
  );
}
