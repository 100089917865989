/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { addSlotsToStory } from '../../../utils/story-ads-from-pb';
import { getPath } from '../../utils';

export const getStoryDataWithAdSlots = async (storyId, storySlots, sketchesHost) => {
  const url = getPath({ sketchesHost, relativePath: `/api/v1/stories/${storyId}` });
  const { story } = await (await fetch(url)).json();
  // inserts slots in place
  const { storyCards, customSlotAfterStory = {} } = addSlotsToStory(
    story.cards,
    storySlots,
    story.customSlotAfterStory
  );
  story.cards = storyCards;
  story.customSlotAfterStory = customSlotAfterStory;
  return { story };
};
